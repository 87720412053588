import { HttpMethods, APIContexts } from 'enums';
import { apiRequestHandler, apiHost } from '.';

const apiContext = `${APIContexts.USER}/`;

export const GetGridStateAPI = ({ queryParams, onSuccess, ...rest }) => {
  const api = 'GetGridState';
  const endpoint = `${apiHost}${apiContext}${api}?grid=${queryParams.gridName}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const SaveGridStateAPI = ({ requestBody, onSuccess, ...rest }) => {
  const api = 'SaveGridState';
  const endpoint = `${apiHost}${apiContext}${api}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.POST,
    payload: requestBody,
    onSuccess: (res) => onSuccess && onSuccess(res),
    ...rest,
  });
};


