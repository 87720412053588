import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

function TextArea({
  ref,
  label,
  name,
  type,
  placeHolder,
  value,
  showErrorMessage,
  errorMessage,
  maxLength,
  isRequired,
  width,
  onChange,
  onFocus,
  onBlur,
  disabled,
  pattern,
}) {
  const handleOnBlur = (e) => {
    if (typeof onChange === 'function') {
      e.target.value = e.target.value.replace(/\s+/g, ' ').trim();
      onChange(e);
    }
  };

  return (
    <>
      <textarea
        className={`${styles.textarea} ${showErrorMessage &&
          styles.errorInput}`}
        value={value && value}
        name={name && name}
        onChange={onChange}
        onBlur={handleOnBlur}
      />
    </>
  );
}

TextArea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeHolder: PropTypes.string,
  value: PropTypes.string,
  showErrorMessage: PropTypes.bool,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.string,
  isRequired: PropTypes.bool,
  width: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

TextArea.defaultProps = {
  label: null,
  name: null,
  type: 'text',
  placeHolder: null,
  value: null,
  showErrorMessage: false,
  errorMessage: null,
  maxLength: null,
  isRequired: false,
  width: null,
  onChange: null,
  onFocus: null,
  onBlur: null,
  disabled: false,
};

export default TextArea;
