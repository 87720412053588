export const HttpMethods = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const APIContexts = {
  CHANNEL: 'Channel',
  PLAYLIST: 'Playlist',
  SCHEDULE: 'Schedule',
  USER: 'User',
  SEASON: 'Season',
  TITLEGROUP: 'TitleGroup',
};
