import axios from 'axios';

export const apiRequestHandler = (props) => {
  const {
    endpoint,
    method,
    setLoader = null,
    onSuccess = null,
    onError = null,
    callBack = null,
    payload = null,
    responseType = 'json',
  } = props;

  setLoader && setLoader(true);
  axios({
    method: method,
    url: endpoint,
    data: payload,
    responseType: responseType,
  })
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((err) => {
      onError && onError(err);
      console.error(`API ERROR: (${endpoint})`, err);
    })
    .finally(() => {
      callBack && callBack();
      setTimeout(() => {
        setLoader && setLoader(false);
      }, 300);
    });
};

export const apiHost = process.env.REACT_APP_HOST_URL;
