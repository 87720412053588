import { formatDateWithMoment } from 'utils';

const PrioritisationForms = {
  channelOptions: ['Sky Channel 1', 'Sky Channel 2', 'Sky Channel 3'],

  availableProgramsHeader: [
    'Title Name',
    'Genre',
    'Duration',
    'Grade',
    'Certificate',
    'Year',
    '',
  ],

  months: [
    {
      id: 1,
      value: 'January',
    },
    {
      id: 2,
      value: 'February',
    },
    {
      id: 3,
      value: 'March',
    },
    {
      id: 4,
      value: 'April',
    },
    {
      id: 5,
      value: 'May',
    },
    {
      id: 6,
      value: 'June',
    },
    {
      id: 7,
      value: 'July',
    },
    {
      id: 8,
      value: 'August',
    },
    {
      id: 9,
      value: 'September',
    },
    {
      id: 10,
      value: 'October',
    },
    {
      id: 11,
      value: 'November',
    },
    {
      id: 12,
      value: 'December',
    },
  ],

  year() {
    const yearlist = [];
    const start = 2022;

    for (let i = start; i <= start + 10; i += 1) {
      yearlist.push({ id: i, name: i });
    }

    return yearlist;
  },

  prioritisedProgramsHeader: ['Title Name'],
  sampleExportData: [
    { title: 'Knuckledust' },
    { title: 'Shooter' },
    { title: 'Dark Web: Cicada 3301' },
    { title: "School's Out Forever" },
    { title: 'Archenemy' },
    { title: 'Jurassic World' },
    { title: 'Shooter' },
    { title: 'Sherlock Holmes: A Game Of Shadows' },
  ],
  importModalComponents: {
    import: 'import',
    confirm: 'confirm',
    loading: 'loading',
    success: 'success',
  },

  tableOneHeaders: [
    {
      headerId: 1,
      field: 'title',
      headerName: 'Title Name',
      pinned: 'left',
      width: 166,
      sortable: true,
    },
    {
      headerId: 2,
      headerName: 'Genre',
      width: 100,
      sortable: true,
    },
    {
      headerId: 3,
      headerName: 'Duration',
      width: 100,
      sortable: true,
    },
    {
      headerId: 4,
      field: 'grade',
      headerName: 'Grades',
      width: 100,
    },
    {
      headerId: 5,
      headerName: 'Certificate',
      width: 100,
      filter: {
        type: 1,
        queryNames: ['certificateFilter', 'certificateFilterValue'],
      },
      sortable: true,
    },
    {
      headerId: 6,
      headerName: 'Year',
      width: 100,
      filter: {
        type: 5,
        queryNames: ['yearFilter', 'yearFilterValue'],
      },
      sortable: true,
    },
    {
      headerId: 7,
      field: 'startDate',
      headerName: 'Start Date',
      width: 200,
      sortable: true,
      valueFormatter: (params) =>
        `${formatDateWithMoment(params.value, 'DD/MM/YYYY')}`,
    },
    {
      headerId: 8,
      field: 'endDate',
      headerName: 'End Date',
      width: '200px',
      sortable: true,
      valueFormatter: (params) =>
        `${formatDateWithMoment(params.value, 'DD/MM/YYYY')}`,
    },
    {
      headerName: 'Runs',
      width: '100px',
      sortable: true,
    },
    {
      headerName: 'Series Name',
      field: 'series',
      width: '100px',
      sortable: true,
    },
    {
      headerName: 'Season',
      field: 'seasonNumber',
      width: '100px',
      sortable: true,
    },
    {
      headerName: 'Season Name',
      field: 'seasonName',
      width: '100px',
      sortable: true,
    },
    {
      headerName: 'Episode',
      field: 'episodeNumber',
      width: '100px',
      sortable: true,
    },
    {
      headerName: 'Episode Name',
      field: 'episodeName',
      width: '100px',
      sortable: true,
    },
    {
      headerName: 'External ID',
      field: 'externalID',
      sortable: true,
    },
    {
      headerId: 9,
      headerName: 'View details',
      hiddenHeaderName: true,
      width: '42px',
      pinned: 'right',
    },
  ],
  importModal: {
    titles: {
      title: 'Import program titles',
      description: 'There are already existing included program titles',
      exportResultFile: 'included_program_title_import_results',
      resultHeader: 'PROGRAM TITLE',
      exportSampleFileName: 'included_program_titles_import_template',
    },
    titlegroups: {
      title: 'Import title groups',
      description: 'There are already existing included title groups',
      exportResultFile: 'included_title_group_import_results',
      resultHeader: 'TITLE GROUPS',
      exportSampleFileName: 'included_title_groups_import_template',
    },
    interstitials: {
      title: 'Import interstitials',
      description: 'There are already existing included interstitials',
      exportResultFile: 'included_interstitial_import_results',
      resultHeader: 'INTERSTITIALS',
      exportSampleFileName: 'included_interstitials_import_template',
    },
    seasons: {
      title: 'Import seasons',
      description: 'There are already existing included seasons',
      exportResultFile: 'included_season_import_results',
      resultHeader: 'SEASON NAME',
      exportSampleFileName: 'included_seasons_import_template',
    },
  },
  sampleExportTitleGroupData: [
    { titleGroup: 'Title Group Name 1' },
    { titleGroup: 'Title Group Name 2' },
    { titleGroup: 'Title Group Name 3' },
    { titleGroup: 'Title Group Name 4' },
    { titleGroup: 'Title Group Name 5' },
    { titleGroup: 'Title Group Name 6' },
    { titleGroup: 'Title Group Name 7' },
    { titleGroup: 'Title Group Name 8' },
  ],
  prioritisationTabs: {
    titles: 'Titles',
    titleGroups: 'Title Groups',
    interstitials: 'Interstitials',
    seasons: 'Seasons',
  },
  sampleExportInterstitialsData: [
    'OBB 1',
    'OBB 2',
    'CBB 1',
    'CBB 2',
    'Filler 1',
    'Filler 2',
    'Filler 3',
    'Filler 4',
  ],
  componentState: {
    interstitials: {
      tableLabels: ['Interstitials:', 'Included interstitials:'],
      verbiages: [
        {
          show: false,
          message: 'No interstitials found.',
        },
        {
          show: false,
          message: 'Please select and add interstitials to include.',
        },
      ],
      headers: [
        {
          headerId: 1,
          headerName: 'Interstitials Name',
          field: 'name',
          sortable: true,
        },
        {
          headerId: 2,
          headerName: 'Duration',
          field: 'duration',
          sortable: true,
        },
        {
          headerId: 3,
          headerName: 'Type',
          field: 'type',
          sortable: true,
        },
        {
          headerId: 4,
          headerName: 'External ID',
          field: 'externalID',
          sortable: true,
        },
        {
          headerId: 5,
          headerName: '‎',
          width: '42px',
        },
      ],
      detailLabel: 'Type',
    },
    seasons: {
      tableLabels: ['Seasons:', 'Included seasons:'],
      verbiages: [
        {
          show: false,
          message: 'No seasons found.',
        },
        {
          show: false,
          message: 'Please select and add seasons to include.',
        },
      ],
      headers: [
        {
          headerId: 1,
          headerName: 'Season Name',
          field: 'name',
          sortable: true,
        },
        {
          headerId: 3,
          headerName: '‎',
          width: '42px',
        },
      ],
      detailLabel: 'Program Titles',
    },
  },
  sampleExportSeasonData: [
    'Friends Season 1',
    'Friends Season 2',
    'Friends Season 3',
    'Friends Season 4',
    'Friends Season 5',
    'Friends Season 6',
    'Friends Season 7',
    'Friends Season 8',
  ],
};

export default PrioritisationForms;
