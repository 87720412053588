const RouterService = {
  isValidUrl(url) {
    const validUrls = [
      '/',
      '/organisation',
      '/email-triggers',
      '/architecture',
      '/break-patterns',
      '/channel-profiles',
      '/title-groups',
      '/prioritisation',
      '/title-exclusions',
      '/attributes-override',
      '/schedule',
      '/scheduler',
      '/scheduledetails',
      '/submission',
      '/submission-scheduler',
      '/users',
      '/channel-groups',
      '/channels',
      '/access-denied',
      '/not-found',
      '/request-timed-out',
      '/maintenance',
      '/profile',
      '/playlist',
      '/view-schedules',
      '/inclusion',
    ];
    return isAccessible(validUrls, url);
  },
  isAccessibleForSuperAdmin(url) {
    const accessiblePages = [
      '/',
      '/users',
      '/organisation',
      '/channel-groups',
      '/channels',
      '/email-triggers',
      '/access-denied',
      '/not-found',
      '/request-timed-out',
      '/maintenance',
      '/profile',
    ];
    return isAccessible(accessiblePages, url);
  },

  isAccessibleForOrganisationalAdmin(url) {
    const accessiblePages = [
      '/',
      '/architecture',
      '/break-patterns',
      '/channel-profiles',
      '/title-groups',
      '/prioritisation',
      '/title-exclusions',
      '/attributes-override',
      '/schedule',
      '/scheduler',
      '/scheduledetails',
      '/submission',
      '/submission-scheduler',
      '/users',
      '/channel-groups',
      '/channels',
      '/access-denied',
      '/not-found',
      '/request-timed-out',
      '/maintenance',
      '/profile',
      '/playlist',
      '/view-schedules',
      '/inclusion',
    ];
    return isAccessible(accessiblePages, url);
  },

  isAccessibleForOrganisationalUser(url) {
    const accessiblePages = [
      '/',
      '/architecture',
      '/break-patterns',
      '/channel-profiles',
      '/title-groups',
      '/prioritisation',
      '/title-exclusions',
      '/attributes-override',
      '/schedule',
      '/scheduler',
      '/scheduledetails',
      '/submission',
      '/submission-scheduler',
      '/access-denied',
      '/not-found',
      '/request-timed-out',
      '/maintenance',
      '/profile',
      '/playlist',
      '/view-schedules',
      '/inclusion',
    ];
    return isAccessible(accessiblePages, url);
  },
};
const isAccessible = (accessiblePages, url) => {
  return (
    accessiblePages.includes(url) ||
    url.includes('/internal-server') ||
    url.includes('/passwordreset')
  );
};
export default RouterService;
