export const ActionTypes = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  UPDATEALL: 'UPDATEALL',
  UNDO: 'UNDO',
};
export const Layouts = [
  { id: 1, name: 'Stacked' },
  { id: 2, name: 'Striped' },
];
export const BlockTypes = [
  { id: 1, name: 'Movies' },
  { id: 2, name: 'Placeholder' },
  { id: 3, name: 'Series' },
  { id: 4, name: 'TitleGroup' },
];

export const defaultModalState = {
  disableGenre: false,
  disableMaxCount: false,
  disableAvailableDuration: false,
  disableSeason: true,
  disableSeries: true,
  disableTitleGroup: true,
  disableLayout: true,
  disableSequential: false,
  disableStartTime: false,
  type: 'Movies',
  genre: '',
  programMaxCount: null,
  programAvailableDuration: null,
  series: null,
  seasons: null,
  titleGroup: null,
  weekNumber: 1,
  dayOfWeek: { id: 1, name: 'Monday' },
  startTime: new Date(),
  endTime: new Date(),
  hasLink: false,
  layout: 'Stacked',
  linkReference: null,
  sequential: false,
  errorMessage: '',
};

export const daysAbbreviation = [
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun',
];
