import { Modal } from 'react-bootstrap';
import styles from './styles.module.css';
import Button from '../../../../shared/components/button/Button';

const ConfirmLeavePageModalComponent = ({
  show,
  onHide,
  onSave,
  onDiscard,
}) => {
  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Body className={styles.modalBody}>
        <div>
          You have unsaved changes, are you sure you want to navigate away from
          this page?
        </div>
        <div className={styles.modalBodyButtons}>
          <Button
            text="Save and proceed"
            onClick={onSave}
            style={{ width: '100%', marginTop: '20px' }}
          />
          <Button
            text="Discard changes and proceed"
            onClick={onDiscard}
            style={{ width: '100%', marginTop: '20px' }}
          />
          <Button
            defaultBtn
            text="Cancel"
            onClick={onHide}
            style={{ width: '100%', marginTop: '20px' }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmLeavePageModalComponent;
