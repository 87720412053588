export const applyCellClassMethod = (params) => {
  /**
   * This function determines the CSS class for the cell in an ag-grid column based on the row's selection status.
   * If the row is selected, the cell background color is set to the specified selected row background color (--ag-selected-row-background-color).
   * If the row is not selected, the cell background color alternates between two designated colors (--op2mise-color-ash-gray) and --op2mise-color-light-gray.
   * Prevents overlapping of styles betweeen the --ag-selected-row-background-color and the 'booked' auto cell styling
   * */
  const selectedRowBackgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--ag-selected-row-background-color');
  const bookedColumnStyle = (backgroundColor) => {
    return {'textAlign': 'right', 'backgroundColor': backgroundColor, 'boxShadow': '3px 0px 4px rgba(0, 0, 0, 0.10) inset' };
  }
  if (params) {
    if (params.node.isSelected()) {
      return {...bookedColumnStyle(selectedRowBackgroundColor)}
    } else {
      return params.rowIndex % 2 !== 0 ? bookedColumnStyle('var(--op2mise-color-ash-gray)') : bookedColumnStyle('var(--op2mise-color-light-gray)');
    }
  }
};

export const handleCapitalization = (columnInstance) => {
  /**
   * On initial load, this function maps through the list of column definitions and capitalizes the field/header names of each column.
   * If a column has children, it sets the 'columnIsWithChildren' state  to true.
   * See implementation of 'columnIsWithChildren' state in 'handleChildColumnHeaders' function.
   */
  return columnInstance.map(column => {
    if (column.children && column.children.length) {
      setColumnIsWithChildren(true);
      column.children.map(child => {
        if (child.headerName) {
          child['headerName'] = child.headerName.toUpperCase();
        } else {
          child['headerName'] = child.field.toUpperCase();
        }
        return child;
      });
    }
    return {
      ...column,
      ...(column.headerName ? {headerName: column.headerName.toUpperCase()} : {headerName: column.field.toUpperCase()}),
    };
  });
};

export const handleChildColumnHeaders = (columnInstance) => {
  /**
   * On initial load, this function maps through the column definitions to check if one or more columns define contains a child column.
   * If a column has children, it provides a headerClass named 'ag-header-child', which is responsible for creating
   * a different cell styling for each child column header.
   */
  return columnInstance.map(c => {
    if (c.children && c.children.length) {
      // column has children object
      setColumnIsWithChildren(true);
      c.children.map(child => {
        child['headerClass'] = 'ag-header-child';
        return child;
      });
    }
    return c;
  });
};

export const isVerticalScrollDisplayed = () => {
  const agVerticalScroll = document.getElementsByClassName('ag-body-vertical-scroll');
  return agVerticalScroll[1].className.includes('ag-hidden');
};

export const handleGridStateApplication = (columnDefinition, gridState, mainGridRef) => {
   /**
   * GRID STATE APPLIED:
   * aggregation: NO
   * columnGroup: NO
   * columnOrder: YES;
   * columnPinning: YES;
   * columnSizing: YES;
   * columnVisibility: YES;
   * filter: YES;
   * focusedCell: NO;
   * pagination: NO;
   * pivot: NO;
   * rangeSelection: NO;
   * rowGroup: NO;
   * rowGroupExpansion: NO;
   * rowSelection: NO;
   * scroll: NO;
   * sideBar: NO;
   * sort: YES;
   */
  const columnSizingModel = gridState?.columnSizing?.columnSizingModel || [];
  const columnSortModel = gridState?.sort?.sortModel || [];
  const columnVisibility = gridState?.columnVisibility?.hiddenColIds || null;
  const columnPinning = gridState?.columnPinning || null;

  const arrayMerger = (...arrays) => {
    const mergedMap = new Map();
  
    // Iterate through each array
    arrays.forEach(array => {
      array.forEach(item => {
        const { colId, ...attributes } = item;
  
        // Check if the colId already exists in the mergedMap
        if (mergedMap.has(colId)) {
          // Merge attributes if colId exists
          const existingItem = mergedMap.get(colId);
          Object.assign(existingItem, attributes);  // Combine attributes, overwriting duplicates
        } else {
          // Add new item to the map
          mergedMap.set(colId, { colId, ...attributes });
        }
      });
    });
  
    // Convert the map values back to an array
    return Array.from(mergedMap.values());
  };

  const state = arrayMerger(columnSizingModel, columnSortModel).map(column => {
    if(columnVisibility && gridState.columnVisibility?.hiddenColIds.includes(column.colId)) {
      column['hide'] = true;
    }
    if (columnPinning) {
      columnPinning.leftColIds.forEach(pinnedCols => {
        if (pinnedCols === column.colId) column['pinned'] = 'left';
      });
      columnPinning.rightColIds.forEach(pinnedCols => {
        if (pinnedCols === column.colId) column['pinned'] = 'right';
      });
    }
    return column;
  });

  mainGridRef.current.api.applyColumnState({
    state,
    applyOrder: true, // APPLIES COLUMN ORDER
  });

  // ROW FILTER MODEL APPLICATION
  if (gridState.filter) mainGridRef.current.api.setFilterModel(gridState.filter.filterModel);
};