import { ActionTypes } from '../ChannelArchitecture.d';

export const schedulesReducer = (state, { actionType, payload }) => {
  switch (actionType) {
    case ActionTypes.CREATE:
      return {
        ...state,
        previous:
          state.previous.length == 0
            ? [{ item: [...state.programmes] }]
            : [...state.previous, { item: [...state.programmes] }],
        programmes: [...state.programmes, ...payload.item],
      };

    case ActionTypes.UPDATE:
      return {
        ...state,
        previous: [...state.previous, { item: [...state.programmes] }],
        programmes: state.programmes.map((item) => {
          if (item.id === payload.item.id) {
            return { ...payload.item };
          }
          return item;
        }),
      };

    case ActionTypes.DELETE:
      //remove block references
      const blockName = payload.BlockName;
      const removeReferenceBlocks = state.programmes.filter(
        (o) => o.blockReference === blockName
      );
      const updatePayloads = removeReferenceBlocks.map((value) => {
        return { item: { ...value, blockReference: '', link: false } };
      });
      const deleted = state.programmes.filter((item) => item.id !== payload.Id);
      const toUpdate = deleted.map((item) => {
        if (updatePayloads.find((o) => o.item.id === item.id)) {
          const updatePayload = updatePayloads.find(
            (o) => o.item.id === item.id
          );
          return { ...updatePayload.item };
        }
        return item;
      });
      return {
        ...state,
        previous: [...state.previous, { item: [...state.programmes] }],
        programmes: toUpdate,
      };

    case ActionTypes.UPDATEALL:
      return {
        programmes: payload.programmes,
        previous: [],
      };
    case ActionTypes.UNDO:
      return {
        ...state,
        programmes: state.previous[state.previous.length - 1].item,
        previous: state.previous.slice(0, -1),
      };

    default:
      return state;
  }
};

export const cAModalReducer = (state, payload) => {
  const action = payload.action;
  if (action === 'changeValue') {
    const newValues = payload.newValues;
    return {
      ...state,
      ...newValues,
    };
  } else {
    const type = payload.type;
    switch (type) {
      case 'Movies':
        return {
          ...state,
          type: type,
          disableGenre: false,
          disableMaxCount: false,
          disableAvailableDuration: false,
          disableSeason: true,
          disableSeries: true,
          disableTitleGroup: true,
          disableLayout: true,
          disableSequential: true,
          disableStartTime: false,
          sequential: false,
          series: null,
          seasons: null,
          titleGroup: null,
        };

      case 'Placeholder':
        return {
          ...state,
          type: type,
          disableGenre: true,
          disableMaxCount: true,
          disableAvailableDuration: true,
          disableSeason: true,
          disableSeries: true,
          disableTitleGroup: true,
          disableLayout: true,
          disableSequential: true,
          sequential: false,
          disableStartTime: false,
          series: null,
          seasons: null,
          titleGroup: null,
        };

      case 'Series':
        return {
          ...state,
          type: type,
          disableGenre: false,
          disableMaxCount: false,
          disableAvailableDuration: false,
          disableSeason: false,
          disableSeries: false,
          disableTitleGroup: true,
          disableLayout: false,
          disableSequential: false,
          disableStartTime: false,
          series: null,
          seasons: null,
          titleGroup: null,
        };

      case 'TitleGroup':
        return {
          ...state,
          type: type,
          disableGenre: true,
          disableMaxCount: false,
          disableAvailableDuration: false,
          disableSeason: true,
          disableSeries: true,
          disableTitleGroup: false,
          disableLayout: false,
          disableSequential: false,
          disableStartTime: false,
          series: null,
          seasons: null,
          titleGroup: null,
        };

      default:
        return {
          ...state,
          disableGenre: false,
          disableMaxCount: false,
          disableAvailableDuration: false,
          disableSeries: true,
          disableTitleGroup: true,
          disableLayout: true,
          disableSequential: true,
        };
    }
  }
};
