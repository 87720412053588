import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

function Switch({
  label, labelStyle, text, active, disabled, onSwitch, blurredOnInactive, cssClass
}) {
  const [isActiveSwitch, setIsActiveSwitch] = useState(active);

  useEffect(() => {
    setIsActiveSwitch(active);
  }, [active]);

  const executeOnSwitch = () => {
    if (!disabled) {
      if (typeof onSwitch === 'function') onSwitch(!isActiveSwitch);
      setIsActiveSwitch(!isActiveSwitch);
    }
  };
  return (
    <div className={styles.switchDiv}>
      {text ? (
        <div>
          {!isActiveSwitch ? (
            <div
              className={`${styles.switch} ${disabled
                && (isActiveSwitch
                  ? styles.disabledActiveSwitch
                  : styles.disabledSwitch)} ${styles.switchWithText} ${cssClass}`}
              onClick={executeOnSwitch}
              onKeyDown={executeOnSwitch}
              role="presentation"
            >
              <span className={`${styles.text} ${blurredOnInactive && styles.disabledLabel}`} style={{ ...labelStyle }}>{text[0]}</span>
            </div>
          ) : (
            <div
              className={`${styles.activeSwitch} ${disabled
                && (isActiveSwitch
                  ? styles.disabledActiveSwitch
                  : styles.disabledSwitch)} ${styles.activeSwitchWithText} ${cssClass}`}
                  style={{marginRight: '8px !important'}}
              onClick={executeOnSwitch}
              onKeyDown={executeOnSwitch}
              role="presentation"
            >
              <span className={styles.activeText}>{text && text[1]}</span>
            </div>
          )}
        </div>
      ) : (
        <div
          className={`${
            isActiveSwitch ? styles.activeSwitch : styles.switch
          } ${disabled
            && (isActiveSwitch
              ? styles.disabledActiveSwitch
              : styles.disabledSwitch)} ${cssClass}`}
          onClick={executeOnSwitch}
          onKeyDown={executeOnSwitch}
          role="presentation"
        />
      )}

      <div className={`${styles.label} ${blurredOnInactive && styles.disabledLabel}`} style={{ ...labelStyle }}>{label}</div>
    </div>
  );
}

Switch.propTypes = {
  label: PropTypes.string,
  text: PropTypes.arrayOf([PropTypes.string]),
  disabled: PropTypes.bool,
  onSwitch: PropTypes.func,
  active: PropTypes.bool,
};
Switch.defaultProps = {
  label: null,
  text: null,
  disabled: false,
  onSwitch: null,
  active: false,
};
export default Switch;