import axios from 'axios';

const ViewsService = {
  saveUserLastAccess(json) {
    const url = `${process.env.REACT_APP_HOST_URL}User/SaveUserLastAccess`;
    return axios.post(url, json);
  },

  getUserLastAccess(userId) {
    const url = `${process.env.REACT_APP_HOST_URL}User/GetUserLastAccess/${userId}`;
    return axios.get(url);
  },
};

export default ViewsService;
