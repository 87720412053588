import React, { useState, useEffect } from 'react';

import { Modal } from 'react-bootstrap';
import Button from '../../../shared/components/button/Button';
import Input from '../../../shared/components/input/Input';
import SelectComponent from '../../../shared/elements/select/select.component';
import EmailTriggerService from '../EmailTriggerService';
import styles from './styles.module.css';
import useNotification from 'store/NotificationStore';

const SubmittingSchedulesSendTestModalComponent = ({
  predefinedRecipient,
  show,
  onHide,
}) => {
  const { success } = useNotification((state) => state);
  const [channelLookup, setChannelLookup] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState({});
  const [organisationLookup, setOrganisationLookup] = useState([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState({});
  const [recipients, setRecipients] = useState('');
  const [errorMessages, setErrorMessages] = useState({});
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const handleGetChannelLookup = async () => {
    try {
      var response = await EmailTriggerService.getChannelsByOrganisationLookup(
        selectedOrganisation.id
      );
      const mappedData = response.data.map((item) => ({
        id: item.channelId,
        name: item.channelName,
      }));
      setChannelLookup(mappedData);
    } catch (error) {}
  };

  const handleGetOrganisationLookup = async () => {
    try {
      var response = await EmailTriggerService.getOrganisationLookup();
      const mappedData = response.data.map((item) => ({
        id: item.organisationId,
        name: item.organisationName,
      }));
      setOrganisationLookup(mappedData);
    } catch (error) {}
  };

  const onValidateForm = () => {
    const recipientArr = recipients ? recipients.split(';') : [];
    const emailRegex = /^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/;
    const isRecipientsValid = recipientArr.every((o) => emailRegex.test(o));

    if (
      !recipients ||
      !selectedChannel.name ||
      !selectedOrganisation.name ||
      (!isRecipientsValid && recipients)
    ) {
      setErrorMessages({
        recipientsState: !recipients || (!isRecipientsValid && recipients),
        recipientsMessage:
          !isRecipientsValid && recipients
            ? 'Check the recipient(s) if it is a valid email format'
            : 'Recipient(s) is required',
        channelState: !selectedChannel.name,
        channelMessage: 'Channel is required',
        organisationState: !selectedOrganisation.name,
        organisationMessage: 'Organisation is required',
      });

      return false;
    }

    setErrorMessages({});
    return true;
  };

  const onClickSendATestMail = async () => {
    if (onValidateForm()) {
      setIsSendingEmail(true);
      try {
        await EmailTriggerService.sendTestEmail({
          channelId: selectedChannel.id,
          recipients,
          generating: false,
          organisationId: selectedOrganisation.id,
        });
        success('A test email has been successfully sent');
        onHide();
      } catch (error) {
      } finally {
        setIsSendingEmail(false);
      }
    }
  };

  useEffect(() => {
    handleGetOrganisationLookup();
  }, []);

  useEffect(() => {
    setRecipients(predefinedRecipient);
  }, [predefinedRecipient]);

  useEffect(() => {
    if (selectedOrganisation.id) handleGetChannelLookup();
    setSelectedChannel({});
    setErrorMessages({
      ...errorMessages,
      organisationState: false,
      channelState: false,
    });
  }, [selectedOrganisation]);

  return (
    <Modal
      dialogClassName={styles.generatingScheduleSendTestModal}
      show={show}
      onHide={onHide}
      centered
    >
      <div
        className={styles.generatingScheduleSendTestModal}
        style={{ padding: '30px 50px' }}
      >
        <p className={styles.title}>Trigger - Submitting schedules</p>

        <div className={styles.form}>
          <Input
            label="Recipient(s)"
            value={recipients}
            isRequired
            onChange={(e) => setRecipients(e.target.value)}
            showErrorMessage={errorMessages.recipientsState}
            errorMessage={errorMessages.recipientsMessage}
          />

          <div className={styles.select}>
            <label className={styles.label}>
              Organisation <span style={{ color: '#FF0000' }}>*</span>
            </label>
            <SelectComponent
              text={
                selectedOrganisation.name || 'Please select an organisation'
              }
              list={organisationLookup}
              width="100%"
              withSearch
              onSelect={(data) => setSelectedOrganisation(data)}
              height="127px"
            />
            {errorMessages.organisationState && (
              <span className={styles.errorMessage}>
                {errorMessages.organisationMessage}
              </span>
            )}
          </div>

          <div className={styles.select}>
            <label className={styles.label}>
              Channel <span style={{ color: '#FF0000' }}>*</span>
            </label>
            <SelectComponent
              text={selectedChannel.name || 'Please select a channel'}
              list={channelLookup}
              width="100%"
              withSearch
              onSelect={(data) => {
                setSelectedChannel(data);
                setErrorMessages({
                  ...errorMessages,
                  channelState: false,
                });
              }}
              disabled={!selectedOrganisation.name}
              height="127px"
            />
            <span
              className={styles.errorMessage}
              style={{ opacity: errorMessages.channelState ? '1' : '0' }}
            >
              Channel is required
            </span>
          </div>

          <div style={{ width: '100%', textAlign: 'right', marginTop: '35px' }}>
            <Button text="Cancel" defaultBtn onClick={onHide} />
            <Button
              text="Send a test mail"
              onClick={onClickSendATestMail}
              isLoading={isSendingEmail}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SubmittingSchedulesSendTestModalComponent;
