import { Internationalization } from '@syncfusion/ej2-base';
import './EventTemplate.css';

export const EventTemplate = ({ args }) => {
  const { State, Grade, Certificate, StartTime, EndTime, Subject, Duration } = args;

  /** Converts the date value and extracts the time */
  let instance = new Internationalization();
  const getTimeString = (value) => {
    return instance.formatDate(value, { skeleton: 'hm', format: State.timeFormat });
  };

  /** Converts the time value string into minutes: '02:00' -> (120 minutes) */
  const convertDurationToMinutes = (timeString) => {
    // Split the time string into hours and minutes
    const parts = timeString.split(':');
    // Convert hours to minutes and add minutes
    return `${parseInt(parts[0]) * 60 + parseInt(parts[1])} minutes`;
  };


  return (
    <div className='e-schedule-custom-event'>
      <div className='e-schedule-custom-event-title'>{Subject}</div>
      <div className='e-schedule-custom-event-sub-title'>
        <div className='e-schedule-custom-event-sub-title-schedule-duration'>{Duration ? `${getTimeString(StartTime)} - ${getTimeString(EndTime)}, (${convertDurationToMinutes(Duration)})` : `${getTimeString(StartTime)} - ${getTimeString(EndTime)}`}</div>
      </div>
    </div>
  );
}