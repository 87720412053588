import { HttpMethods, APIContexts } from 'enums';
import axios from 'axios';
import { apiRequestHandler, apiHost } from '.';

const apiContext = `${APIContexts.CHANNEL}/`;

export const GetChannelLookupAPI = (props) => {
  const api = 'GetChannelLookup';
  const endpoint = `${apiHost}${apiContext}${api}`;

  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    ...props,
  });
};

export const GetGradeArchitectureAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'GetGradeArchitecture';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.channelId}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const ImportChannelArchitectureAPI = (channelId, file) => {
  const formData = new FormData();
  formData.append('file', file);
  const api = 'ImportChannelArchitecture';
  const url = `${apiHost}${apiContext}${api}?channelId=${channelId}`;
  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const ImportGradeArchitectureAPI = (channelId, file) => {
  const formData = new FormData();
  formData.append('file', file);
  const api = 'ImportGradeArchitecture';
  const url = `${apiHost}${apiContext}${api}?channelId=${channelId}`;
  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const GetChannelArchitectureAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'GetChannelArchitecture';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.channelId}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetChannelPlaylistByDayAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'GetChannelPlaylistByDay';
  const endpoint = `${apiHost}${apiContext}${api}?ChannelId=${queryParams.channelId}&Day=${queryParams.date}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetMinutageArchitectureAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'GetMinutageArchitecture';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.channelId}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const ImportMinutageArchitectureAPI = (channelId, file) => {
  const formData = new FormData();
  formData.append('file', file);
  const api = 'ImportMinutageArchitecture';
  const url = `${apiHost}${apiContext}${api}?channelId=${channelId}`;
  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const GetChannelDraftPlaylistByDayAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'GetChannelDraftPlaylistByDay';
  const endpoint = `${apiHost}${apiContext}${api}?DraftScheduleParentID=${queryParams.scheduleParentId}&ChannelId=${queryParams.channelId}&Day=${queryParams.day}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetBreakChannelTemplateAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'GetBreakChannelTemplate';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.channelId}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetBreakChannelTypeAPI = ({ queryParams, onSuccess, ...rest }) => {
  const api = 'GetBreakChannelType';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.breakChannelTemplateId}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const ImportBreakPatternsAPI = ({ channelId, file }) => {
  const formData = new FormData();
  formData.append('file', file);
  const api = 'ImportBreakPatterns';
  const url = `${apiHost}${apiContext}${api}/${channelId}`;
  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const SaveBreakChannelTemplateAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'SaveBreakChannelTemplate';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.breakChannelTemplateId}`;
  apiRequestHandler({
    endpoint,
    payload: queryParams.payload,
    method: HttpMethods.POST,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const ExportChannelArchitectureAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'ExportChannelArchitecture';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.channelId}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    responseType: 'arraybuffer',
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const ExportBreakChannelTypeAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'ExportBreakChannelType';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.breakChannelTemplateId}`;
  apiRequestHandler({
    endpoint,
    payload: queryParams.payload,
    method: HttpMethods.POST,
    responseType: 'arraybuffer',
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const DeleteBreakChannelTemplateAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'DeleteBreakChannelTemplate';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.id}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.DELETE,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetChannelBlockReferenceAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'GetChannelBlockReference';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.channelId}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};
export const SaveChannelArchitectureAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'SaveChannelArchitecture';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.channelId}`;
  apiRequestHandler({
    endpoint,
    payload: queryParams.payload,
    method: HttpMethods.POST,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};
