const BreakPatternForms = {
  sampleUploadFile: [
    {
      Type: 'Promo',
      SlotPosition: 'After Asset',
      Optional: 'Yes',
      Priority: '',
      Selection: 'Rotation',
      Variable: 'No',
      AssetID: '',
      EventName: '',
      Position: '1',
    },
    {
      Type: 'ID',
      SlotPosition: 'After Asset',
      Optional: 'No',
      Priority: '',
      Selection: 'Ad-hoc',
      Variable: 'Yes',
      AssetID: '',
      EventName: '',
      Position: '2',
    },
    {
      Type: 'Slate',
      SlotPosition: 'All But Last',
      Optional: 'No',
      Priority: '',
      Selection: 'Fixed',
      Variable: 'No',
      AssetID: '',
      EventName: '',
      Position: '3',
    },
    {
      Type: 'Promo',
      SlotPosition: 'Before Asset',
      Optional: 'Yes',
      Priority: '',
      Selection: 'Random',
      Variable: 'No',
      AssetID: '',
      EventName: '',
      Position: '4',
    },
  ],
  importModalComponents: {
    import: 'import',
    confirm: 'confirm',
    loading: 'loading',
    success: 'success',
  },
};

export default BreakPatternForms;
