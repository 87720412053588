import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { SidebarComponent } from '@syncfusion/ej2-react-navigations';
import { DataGrid } from 'op2mise-react-widgets/DataGrid';
import CloseIcon from 'assets/icons/Close-Green.png';
import './InterstitialPanel.css'

export const InterstitialPanel = (props) => {
  const {
    content,
    subTitle,
    title,
    interstitialRef,
    toggleInterstitialSidebar,
  } = props;

  return (
    <SidebarComponent
      className='e-sidebar-component-interstitial'
      closeOnDocumentClick={false}
      id='apiSidebar'
      position={'Right'}
      ref={interstitialRef}
      showBackdrop={false}
      target='.overview-content'
      type={'Push'}
      width='90%'
    >
      <div className='e-sidebar-header-interstitial'>
        <img src={CloseIcon} alt='close-icon' onClick={toggleInterstitialSidebar} className='e-sidebar-close-icon'/>
      </div>
      <div className='e-sidebar-content-interstitial'>
        <div className='e-sidebar-content-header-interstitial'>
          <h2>{title || 'Interstitial Panel Header'}</h2>
          <div>{subTitle || 'Interstitial Panel Subtitle'}</div>
        </div>
        <div style={{width: '100%'}}>
          <DataGrid 
            columns={[
              {field: 'startTime', headerName: 'Start Time', flex: 1},
              {field: 'som', flex: 1},
              {field: 'duration', flex: 1},
              {field: 'endTime', headerName: 'End Time', flex: 1},
              {field: 'segment', flex: 1},
              {field: 'title', flex: 1},
              {field: 'assetId', headerName: 'Asset Id', flex: 1},
              {field: 'type', flex: 1},
            ]}
            rows={[
              {
                startTime: '00:02:31:12',
                som: '00:00:00:00',
                duration: '00:00:30:01',
                endTime: '00:03:01:13',
                segment: 1,
                title: 'BUZ_PRO0001',
                assetId: 'BUZ_PRO0001',
                type: 'Promo'
              },
              {
                startTime: '00:03:01:13',
                som: '00:00:00:00',
                duration: '00:02:00:02',
                endTime: '00:05:01:15',
                segment: 2,
                title: 'Slate_120',
                assetId: 'BUZ_PRO0002',
                type: 'Promo'
              },
              {
                startTime: '00:05:01:15',
                som: '00:00:00:00',
                duration: '00:00:45:17',
                endTime: '00:05:47:05',
                segment: 3,
                title: 'BUZ_PRO0003',
                assetId: 'BUZ_PRO0003',
                type: 'Promo'
              },
              {
                startTime: '00:05:47:05',
                som: '00:00:00:00',
                duration: '00:01:20:00',
                endTime: '00:07:07:05',
                segment: 4,
                title: 'BUZ_PRO0004',
                assetId: 'BUZ_PRO0004',
                type: 'Promo'
              },
              {
                startTime: '00:07:07:05',
                som: '00:00:00:00',
                duration: '00:01:45:03',
                endTime: '00:08:52:08',
                segment: 5,
                title: 'BUZ_PRO0005',
                assetId: 'BUZ_PRO0005',
                type: 'Promo'
              },
              {
                startTime: '00:08:52:08',
                som: '00:00:00:00',
                duration: '00:01:10:12',
                endTime: '00:10:02:20',
                segment: 6,
                title: 'Slate_130',
                assetId: 'BUZ_PRO0006',
                type: 'Promo'
              },
              {
                startTime: '00:10:02:20',
                som: '00:00:00:00',
                duration: '00:01:15:04',
                endTime: '00:11:17:24',
                segment: 7,
                title: 'BUZ_PRO0007',
                assetId: 'BUZ_PRO0007',
                type: 'Promo'
              },
              {
                startTime: '00:11:17:24',
                som: '00:00:00:00',
                duration: '00:00:20:10',
                endTime: '00:11:37:04',
                segment: 8,
                title: 'Slate_140',
                assetId: 'BUZ_PRO0008',
                type: 'Promo'
              },
              {
                startTime: '00:11:37:04',
                som: '00:00:00:00',
                duration: '00:01:30:21',
                endTime: '00:13:07:25',
                segment: 9,
                title: 'BUZ_PRO0009',
                assetId: 'BUZ_PRO0009',
                type: 'Promo'
              },
              {
                startTime: '00:13:07:25',
                som: '00:00:00:00',
                duration: '00:00:40:05',
                endTime: '00:13:47:30',
                segment: 10,
                title: 'Slate_150',
                assetId: 'BUZ_PRO0010',
                type: 'Promo'
              }
            ]}
            gridHeightBuffer={400}
            theme='plain'
          />
        </div>
      </div>
    </SidebarComponent>
  );
}