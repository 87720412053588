import React from 'react';
import PropTypes from 'prop-types';
import Styles from './SubmittedScheduleBanner.module.css';

function SubmittedScheduleBannerComponent({ componentName, scheduleDetails }) {
  return (
    <div className={`${Styles.header} position-relative`}>
      <div className={Styles.headerText}>{componentName}</div>
      <div className="d-flex position-absolute end-0">
        <div className={`row ${Styles.marginBottom}`}>
          <div className={Styles.divHeaderPadding}>
            <div className={Styles.channelLabel}>Channel:</div>
          </div>
          <div className={Styles.divHeaderSelect} style={{ width: '125px' }}>
            <span className={Styles.lblText}>
              {scheduleDetails.channelName}
            </span>
          </div>
          <div className={Styles.divHeaderPadding}>
            <div className={Styles.channelLabel}>Month:</div>
          </div>
          <div className={Styles.divHeaderSelect}>
            <span className={Styles.lblText} style={{ width: '120px' }}>
              {scheduleDetails.month}
            </span>
          </div>
          <div className={Styles.divHeaderPadding}>
            <div className={Styles.channelLabel}>Year:</div>
          </div>
          <div className={Styles.divHeaderSelect}>
            <span className={Styles.lblText} style={{ width: '120px' }}>
              {scheduleDetails.year}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
SubmittedScheduleBannerComponent.propTypes = {
  componentName: PropTypes.string.isRequired,
  scheduleDetails: PropTypes.shape.isRequired,
};
export default SubmittedScheduleBannerComponent;
