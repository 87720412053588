import { apiRequestHandler, apiHost } from '.';
import { HttpMethods, APIContexts } from 'enums';

const apiContext = `${APIContexts.SCHEDULE}/`;

export const GetChannelSchedulesAPI = ({ queryParams, onSuccess, ...rest }) => {
  const api = 'GetChannelSchedules';
  const endpoint =
    `${apiHost}${apiContext}${api}?ChannelId=${queryParams.channelId}` +
    (queryParams.startDate ? `&Start=${queryParams.startDate}` : '') +
    (queryParams.endDate ? `&End=${queryParams.endDate}` : '') +
    (queryParams.pageNumber ? `&PageNumber=${queryParams.pageNumber}` : '') +
    (queryParams.pageSize ? `&PageSize=${queryParams.pageSize}` : '');

  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const AddChannelScheduleSessionAPI = ({ queryParams, ...rest }) => {
  const api = 'AddChannelScheduleSession';
  const endpoint =
    `${apiHost}${apiContext}${api}?ChannelID=${queryParams.channelId}` +
    (queryParams.startDate ? `&StartDate=${queryParams.startDate}` : '') +
    (queryParams.endDate ? `&EndDate=${queryParams.endDate}` : '');

  apiRequestHandler({
    endpoint,
    method: HttpMethods.POST,
    ...rest,
  });
};

export const GetChannelScheduleSessionAPI = ({ onSuccess, ...rest }) => {
  const api = 'GetChannelScheduleSession';
  const endpoint = `${apiHost}${apiContext}${api}`;

  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const ExportChannelScheduleAPI = ({
  queryParams,
  responseType,
  ...rest
}) => {
  const api = 'ExportChannelSchedule';
  const endpoint =
    `${apiHost}${apiContext}${api}?ChannelID=${queryParams.channelId}` +
    (queryParams.startDate ? `&Start=${queryParams.startDate}` : '') +
    (queryParams.endDate ? `&End=${queryParams.endDate}` : '');

  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    responseType: responseType,
    ...rest,
  });
};
