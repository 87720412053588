import React, { useEffect, useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import styles from './views.module.css';
// import SidebarComponent from '../shared/sidebar/SidebarComponent';
import SidebarComponent from '../shared/sidebar/sidebar.component';
const UserProfileComponent = React.lazy(() =>
  import('../components/profile/UserProfileComponent')
);
const UserComponent = React.lazy(() =>
  import('../components/user/UserComponent')
);
const PasswordResetComponent = React.lazy(() =>
  import('../components/password-reset/PasswordResetComponent')
);
import viewsRouter from './viewsRouter';
import AuthService from '../core/auth.service';
import ViewsService from './views.service';
import RouterService from '../core/router.service';
import { Modal } from 'react-bootstrap';
import Button from 'shared/components/button/Button';
import useNotification from 'store/NotificationStore';
import { getAllLastAccessed, saveAllLastAccessed } from 'utils';
import { AppModules } from 'enums';
import { SuperAdminModules } from 'enums';

function ViewsComponent({
  toNavigate,
  callbackUserLogout,
  callBackGetVersion,
  isAuthenticated,
}) {
  const { message, handleConfirm, showNotification } = useNotification(
    (state) => state
  );
  const location = useLocation();
  const navigate = useNavigate();
  const userIdentity = AuthService.getUserIdentity();
  const [isProfileUpdated, setIsProfileUpdated] = useState(false);
  const [isAnErrorUrl, setIsAnErrorUrl] = useState();
  const [isSidebarToggled, setIsSidebarToggled] = useState();
  const errorUrlArray = [
    `${process.env.REACT_APP_SUBFOLDER}/not-found`,
    `${process.env.REACT_APP_SUBFOLDER}/internal-server`,
    `${process.env.REACT_APP_SUBFOLDER}/access-denied`,
    `${process.env.REACT_APP_SUBFOLDER}/request-timed-out`,
  ];

  const navigateToDefaultLanding = () => {
    if (userIdentity.roleId === 1) {
      navigate(`${process.env.REACT_APP_SUBFOLDER}/organisation`);
    } else {
      navigate(`${process.env.REACT_APP_SUBFOLDER}/channel-profiles`);
    }
  };

  const getUrl = (roleId, url) => {
    if (RouterService.isValidUrl(url)) {
      switch (roleId) {
        case 2:
          if (RouterService.isAccessibleForOrganisationalAdmin(url)) return url;
          else return '/access-denied';
        case 3:
          if (RouterService.isAccessibleForOrganisationalUser(url)) return url;
          else return '/access-denied';
        default:
          if (RouterService.isAccessibleForSuperAdmin(url)) return url;
          else return '/access-denied';
      }
    } else {
      return '/not-found';
    }
  };

  const handleSession = () => {
    const session = getAllLastAccessed();
    let lastActiveFunction = [];
    let path = '';
    const user = AuthService.getUserIdentity();
    // if (session && session.activeFunction) {
    //   if (user?.organisationName === session.organization) {
    //     if (user.roleId === 2) {
    //       lastActiveFunction = AppModules[session.activeFunction];
    //       path = lastActiveFunction.subModules.find(
    //         (module) => module.name === session.menuItem
    //       ).path;
    //     } else {
    //       lastActiveFunction = SuperAdminModules[session.activeFunction];
    //       path = lastActiveFunction?.subModules
    //         ? lastActiveFunction.subModules.find(
    //             (module) => module.name === session.menuItem
    //           ).path
    //         : lastActiveFunction.path;
    //     }
    //   } else {
    //     const payload =
    //       user?.roleId === 1
    //         ? {
    //             organization: user?.organisationName,
    //             activeFunction: 'ORGANISATIONS',
    //             menuItem: 'Organisations',
    //           }
    //         : {
    //             organization: user?.organisationName,
    //             activeFunction: 'CHANNEL_SETUP',
    //             menuItem: 'Configuration',
    //             channelId: null,
    //             channelName: null,
    //           };
    //     saveAllLastAccessed(payload);
    //     path =
    //       user?.roleId === 1
    //         ? `${process.env.REACT_APP_SUBFOLDER}/organisation`
    //         : `${process.env.REACT_APP_SUBFOLDER}/channel-profiles`;
    //   }
    // } else {
    const payload =
      user.roleId === 1
        ? {
            organization: user.organisationName,
            activeFunction: 'ORGANISATIONS',
            menuItem: 'Organisations',
          }
        : {
            organization: user.organisationName,
            activeFunction: 'CHANNEL_SETUP',
            menuItem: 'Configuration',
            channelId: null,
            channelName: null,
          };
    saveAllLastAccessed(payload);
    path =
      user.roleId === 1
        ? `${process.env.REACT_APP_SUBFOLDER}/organisation`
        : `${process.env.REACT_APP_SUBFOLDER}/schedule`;
    //  }
    navigate(`${process.env.REACT_APP_SUBFOLDER}${path}`);
  };

  useEffect(() => {
    if (
      !location.pathname.includes('internal-server') &&
      !errorUrlArray.includes(location.pathname)
    ) {
      navigate(toNavigate);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toNavigate]);

  useEffect(() => {
    setIsAnErrorUrl(errorUrlArray.includes(location.pathname));

    if (location.pathname === '/' || location.pathname === '/login') {
      const lastAccessPage = userIdentity.lastAccessPage;
      // if (lastAccessPage) {
      //   if (lastAccessPage == '/scheduler')
      //     navigate(process.env.REACT_APP_SUBFOLDER + '/schedule');
      //   else navigate(process.env.REACT_APP_SUBFOLDER + lastAccessPage);
      // } else {
      //   navigateToDefaultLanding();
      // }
      handleSession();
    } else {
      const pathName = getUrl(userIdentity.roleId, location.pathname);
      navigate(process.env.REACT_APP_SUBFOLDER + pathName);
    }
    if (
      !location.pathname.includes('internal-server') &&
      !errorUrlArray.includes(location.pathname) &&
      !location.pathname.includes('/passwordreset')
    ) {
      try {
        ViewsService.saveUserLastAccess({
          userId: userIdentity.userId,
          url: location.pathname,
        });
      } catch (error) {}
    }
    callBackGetVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className={styles.views}>
      <div
        className={`${styles.sidebar} 
          ${isSidebarToggled && styles.vaiShrinkSidebar}`}
        style={{
          display:
            (isAnErrorUrl || location.pathname.includes('internal-server')) &&
            'none',
        }}
      >
        <SidebarComponent
          toggleSidebar={(state) => setIsSidebarToggled(state)}
          callbackUserLogout={callbackUserLogout}
          isProfileUpdated={isProfileUpdated}
          setIsProfileUpdated={setIsProfileUpdated}
        />
      </div>
      <div
        className={
          isAnErrorUrl || location.pathname.includes('internal-server')
            ? styles.errorPage
            : styles.content
        }
      >
        <Routes>
          <Route
            path={`${process.env.REACT_APP_SUBFOLDER}/profile`}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <UserProfileComponent
                  setIsProfileUpdated={setIsProfileUpdated}
                />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.REACT_APP_SUBFOLDER}/users`}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <UserComponent setIsProfileUpdated={setIsProfileUpdated} />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.REACT_APP_SUBFOLDER}/passwordreset/:urlParamId`}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <PasswordResetComponent
                  callbackUserLogout={callbackUserLogout}
                  isAuthenticated={isAuthenticated}
                />
              </Suspense>
            }
          />
          {viewsRouter.routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <Suspense fallback={<div>Loading</div>}>
                  {route.element}
                </Suspense>
              }
            />
          ))}
        </Routes>
        <Modal
          size="md"
          show={showNotification}
          onHide={handleConfirm}
          className="modalNotification"
          style={{ top: '30%' }}
        >
          <Modal.Body style={{ padding: '35px' }}>
            <div style={{ textAlign: 'center', fontWeight: 'bolder' }}>
              {message}
            </div>

            <div
              style={{
                textAlign: 'center',
                paddingTop: '30px',
              }}
            >
              <Button
                text="Close"
                defaultBtn={true}
                onClick={handleConfirm}
                style={{ width: '100%' }}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
ViewsComponent.propTypes = {
  callbackUserLogout: PropTypes.func.isRequired,
};
export default ViewsComponent;
