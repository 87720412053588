import React, { useState, version } from 'react';
import { GetBreakChannelTypeAPI } from 'api';
import { DataGrid } from 'op2mise-react-widgets';
import { useCallbackPrompt } from '../_reusable/blocker/UsePromptComponent.ts';
import Button from 'shared/components/button/Button';
import Input from 'shared/components/input/Input';
import Switch from 'shared/components/switch/Switch';
import { uploadFileIcon, downloadFileIcon } from '../../assets/index';
import './BreakPatterns.css';
import useStore from 'store/AccountStore';
import ImportFileModalComponent from './modals/ImportFileModalComponent';
import BreakPatternForms from './BreakPatternForms';
import { saveAs } from 'file-saver';
import XLSX from 'sheetjs-style';
import useNotification from 'store/NotificationStore';
import { SaveBreakChannelTemplateAPI, ExportBreakChannelTypeAPI } from 'api';
import ConfirmLeavePageModalComponent from 'components/title-group/modals/confirm-leave-page-modal/ConfirmLeavePageModalComponent';
import { padStart } from '@fullcalendar/react';

const BreakPatternEditor = ({
  mode,
  setMode,
  onClose,
  onImport,
  selectedRow,
  setSelectedRow,
  channelInfo,
  setIsDirty,
  isDirty,
}) => {
  const { user } = useStore((state) => state);
  const { success } = useNotification((state) => state);
  const [versionName, setVersionName] = React.useState(selectedRow.version);
  const [status, setStatus] = React.useState(null);
  const [versionData, setVersionData] = React.useState([]);
  const [isDirtyForm, setDirtyForm] = React.useState(false);
  const [showConfirmLeaveModal, setShowConfirmLeaveModal] = React.useState(
    false
  );
  const { sampleUploadFile, importModalComponents } = BreakPatternForms;
  const [uploadState, setUploadState] = React.useState({
    modalComponent: importModalComponents.import,
    errorMessage: '',
    resultList: [],
    show: false,
  });
  const { channelId, channelName } = channelInfo;
  const [sortingResult, setSortingResult] = useState({
    ascending: true,
  });

  const [
    showPrompt,
    confirmNavigation,
    cancelNavigation,
    hidePrompt,
  ] = useCallbackPrompt(isDirtyForm);

  // Input properties
  const props = {
    label: 'Version Name',
    labelStyle: {
      fontWeight: 'bold',
      color: '#6D6D73',
    },
    name: 'versionName',
    type: 'text',
    placeholder: 'Enter version Name',
    value: versionName,
    errorMessage: '',
    maxLength: 99,
    isRequired: false,
    width: '40%',
    onChange: (e) => {
      setVersionName(e.target.value);
    },
    onFocus: () => {},
    onBlur: () => {},
    onClick: () => {},
    disabled: false,
    pattern: '',
    style: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '20px',
    },
    textAlign: 'left',
  };
  // Switch properties
  const switchProps = React.useMemo(
    () => ({
      label: 'Active',
      labelStyle: {
        fontWeight: 'bold',
        color: '#6D6D73',
      },
      text: ['No', 'Yes'],
      active: status ?? selectedRow.status === 'Active',
      disabled: versionData?.length === 0,
      onSwitch: (e) => {
        setStatus(e);
      },
      blurredOnInactive: false,
    }),
    [versionData]
  );
  // Column definitions for the data grid
  const columnDefinitions = React.useMemo(
    () => [
      {
        field: 'type',
        headerName: 'Type',
        headerTooltip: 'Type',
        filterParams: {
          valueFormatter: (params) => {
            return params.value.charAt(0).toUpperCase() + params.value.slice(1);
          },
        },
        valueFormatter: (params) => {
          return params.value.charAt(0).toUpperCase() + params.value.slice(1);
        },
      },
      {
        field: 'slotPosition',
        headerName: 'Slot Position',
        headerTooltip: 'Slot Position',
      },
      {
        field: 'optional',
        headerName: 'Optional',
        headerTooltip: 'Optional',
        cellDataType: 'text',
        filterParams: {
          valueFormatter: (params) => (params.value ? 'Yes' : 'No'),
        },
        valueFormatter: (params) => (params.value ? 'Yes' : 'No'),
      },
      {
        field: 'position',
        headerName: 'Position',
        headerTooltip: 'Position',
        sort: 'asc',
        cellStyle: { textAlign: 'right' },
      },
      {
        field: 'selection',
        headerName: 'Selection',
        headerTooltip: 'Selection',
        filterParams: {
          valueFormatter: (params) => {
            return params.value.charAt(0).toUpperCase() + params.value.slice(1);
          },
        },
        valueFormatter: (params) => {
          return params.value.charAt(0).toUpperCase() + params.value.slice(1);
        },
      },
      {
        field: 'variable',
        headerName: 'Variable',
        headerTooltip: 'Variable',
        cellDataType: 'text',
        filterParams: {
          valueFormatter: (params) => (params.value ? 'Yes' : 'No'),
        },
        valueFormatter: (params) => (params.value ? 'Yes' : 'No'),
      },
      {
        field: 'externalID',
        headerName: 'Asset',
        headerTooltip: 'Asset',
      },
      {
        field: 'eventName',
        headerName: 'Event',
        headerTooltip: 'Event',
      },
      {
        field: 'priority',
        headerName: 'Priority',
        headerTooltip: 'Proirity',
        cellStyle: { textAlign: 'right' },
      },
    ],
    []
  );

  // Returns all fields from columnDefinition except the action column
  const headerFields = columnDefinitions
    .filter((column) => column.field !== 'action')
    .map((column) => column.field);

  const defaultSortingResult = () => {
    setSortingResult({
      ...sortingResult,
      ...headerFields.reduce((column, field) => {
        column[field] = false;
        return column;
      }, {}),
    });
  };

  const fetchChannelTemplate = (templateId) => {
    GetBreakChannelTypeAPI({
      queryParams: { breakChannelTemplateId: templateId },
      onSuccess: (res) => {
        setVersionData(res);
        defaultSortingResult();
      },
    });
  };

  const changeImportModal = (attribute, value) => {
    setUploadState((prevState) => ({
      ...prevState,
      [attribute]: value,
    }));
  };

  const handeImportFile = () => {
    if (versionData.length > 0) {
      setUploadState({
        ...uploadState,
        modalComponent: importModalComponents.confirm,
      });
      setDirtyForm(true);
    } else {
      confirmImportFile();
    }
  };

  const confirmImportFile = async () => {
    setUploadState({
      ...uploadState,
      modalComponent: importModalComponents.loading,
    });
    var res = await onImport(uploadState.selectedFile);
    if (res.status == 200) {
      setUploadState({
        ...uploadState,
        resultList: res.data.items,
        modalComponent: importModalComponents.success,
      });
      setDirtyForm(true);
      const importedData = res.data.items
        .filter((o) => o.imported)
        .map((value) => {
          return {
            type: value.type,
            slotPosition: value.slotPosition,
            optional: value.optional === 'Yes' ? true : false,
            priority: value.priority,
            selection: value.selection,
            variable: value.variable === 'Yes' ? true : false,
            externalID: value.externalID,
            eventName: value.eventName,
            position: parseInt(value.position),
            assetID: parseInt(value.assetID),
          };
        });
      setVersionData(importedData);
    }
  };

  const handleUploadModal = () => {
    setUploadState({
      errorMessage: '',
      resultList: [],
      modalComponent: importModalComponents.import,
      show: true,
    });
  };

  const handleExportSampleTemplate = () => {
    const excelData = sampleUploadFile;
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileName = `break-pattern-import-template.xlsx`;
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { errors: ws }, SheetNames: ['errors'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName);
  };

  const handleDownload = () => {
    if (versionData.length > 0) {
      ExportBreakChannelTypeAPI({
        queryParams: {
          breakChannelTemplateId: selectedRow.id,
          payload: { sort: sortingResult },
        },
        onSuccess: (res) => {
          const { organisationName } = user;
          let exportDate = new Date();
          const exportedDateString =
            padStart(exportDate.getDate(), 2).toString() +
            padStart(exportDate.getMonth() + 1, 2).toString() +
            exportDate.getFullYear().toString();
          const fileName = `${organisationName}_op2mise_break_patterns_export_${channelName}_${exportedDateString}.xlsx`;
          const blob = new Blob([res], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          saveAs(blob, fileName);
        },
      });
    }
  };

  const saveBreakChannelTemplate = () => {
    let data = {};
    let patterns = [];
    if (versionData.length > 0) {
      patterns = versionData.map((pattern) => {
        return {
          type: pattern.type ?? '',
          slotPosition: pattern.slotPosition,
          optional: pattern.optional,
          priority: !pattern.priority ? 0 : pattern.priority,
          selection: pattern.selection,
          isVariableLength: pattern.variable,
          eventName: pattern.eventName,
          position: pattern.position,
          externalID: pattern.externalID,
          assetID: pattern.assetID,
        };
      });
    }
    if (mode === 'new') {
      data = {
        id: null,
        versionName: versionName,
        active: status ?? selectedRow.status === 'Active',
        breakChannelTypes: patterns,
      };
    } else if (mode === 'edit') {
      if (selectedRow) {
        data = {
          id: selectedRow.id,
          versionName: versionName,
          active: status ?? selectedRow.status === 'Active',
          breakChannelTypes: patterns,
        };
      }
    }
    setSelectedRow({
      ...selectedRow,
      version: versionName,
      active: status ? 'Active' : selectedRow.status,
    });
    SaveBreakChannelTemplateAPI({
      queryParams: { breakChannelTemplateId: channelId, payload: data },
      onSuccess: (res) => {
        success('Successfully saved!');
        if (showPrompt) confirmNavigation();
        setIsDirty(true);
        // else onClose('view')
      },
    });
    setDirtyForm(false);
    setShowConfirmLeaveModal(false);
  };

  const handleDiscardChanges = () => {
    if (isDirtyForm) setShowConfirmLeaveModal(false);
    if (showPrompt) cancelNavigation();
    onClose('view');
  };
  const handleDisableOnSaveButton = () => {
    if (mode === 'new') {
      return versionName?.length === 0 || versionData?.length === 0;
    } else {
      return !isDirtyForm;
    }
  };
  React.useEffect(() => {
    if (mode === 'new') {
      if (
        (versionName?.length > 0 && status !== null) ||
        versionData?.length > 0
      ) {
        setDirtyForm(true);
      } else {
        setDirtyForm(false);
      }
    } else {
      if (versionName === selectedRow.version && status === null) {
        setDirtyForm(false);
      } else {
        setDirtyForm(true);
      }
    }
  }, [status, versionName, selectedRow]);

  React.useEffect(() => {
    if (selectedRow.id || isDirty) {
      if (mode === 'new') {
        setMode('edit');
      }
      fetchChannelTemplate(selectedRow.id);
    }
  }, [selectedRow?.id, isDirty]);

  return (
    <div className="break-editor-wrapper">
      <div className="break-editor-header">
        <Input {...props} />
        <Switch {...switchProps} />
        <div className="break-import-export">
          <img
            src={uploadFileIcon}
            alt=""
            title="Import"
            onClick={handleUploadModal}
            style={{
              cursor: 'pointer',
            }}
          />
          <img
            src={downloadFileIcon}
            alt=""
            title="Export"
            onClick={handleDownload}
            style={{
              cursor: 'pointer',
            }}
          />
        </div>
      </div>
      <DataGrid
        columns={columnDefinitions}
        customVerbiage="No break patterns found"
        enableMultipleRowSelection={false}
        enableMultipleColumnSorting={true}
        gridHeightBuffer={345}
        gridName="channelSetup-breakPatterns-editor"
        onStateUpdated={(params) => {
          if (params?.length > 0) {
            let sortRes = params.reduce((column, field) => {
              column[field.colId] = field.sort === 'asc' ? true : false;
              return column;
            }, {});
            setSortingResult({
              ...sortingResult,
              ...sortRes,
            });
          }
        }}
        resizable
        rows={versionData}
        sharedColumnProperties={{
          // cellStyle: { fields: headerFields, value: { textAlign: 'left' } },
          flex: { fields: headerFields, value: 1 },
          sortable: { fields: headerFields, value: true },
          filter: { fields: headerFields, value: true },
        }}
        showAlternativeRowHighlight
        suppressRowClickSelection={true}
      />
      <div className="break-editor-footer">
        <Button
          text="Close"
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            color: '#6d6d73',
          }}
          onClick={() => {
            if (isDirtyForm) setShowConfirmLeaveModal(true);
            else onClose('view');
          }}
        />
        <Button
          text="Save"
          onClick={saveBreakChannelTemplate}
          disabled={handleDisableOnSaveButton()}
        />
      </div>
      <ImportFileModalComponent
        closeModal={() => {
          setUploadState({ ...uploadState, show: false });
        }}
        selectedFile={uploadState.selectedFile}
        setSelectedFile={(e) => changeImportModal('selectedFile', e)}
        onImportFile={handeImportFile}
        errorMessage={uploadState.errorMessage}
        showModal={uploadState.show}
        onExportSampleTemplate={handleExportSampleTemplate}
        modalComponent={uploadState.modalComponent}
        onProceed={confirmImportFile}
        resultList={uploadState.resultList}
      />

      <ConfirmLeavePageModalComponent
        show={showConfirmLeaveModal}
        onHide={() => {
          setShowConfirmLeaveModal(false);
        }}
        onSave={() => {
          saveBreakChannelTemplate();
        }}
        onDiscard={handleDiscardChanges}
      />

      <ConfirmLeavePageModalComponent
        show={showPrompt}
        onHide={cancelNavigation}
        onSave={() => {
          saveBreakChannelTemplate();
        }}
        onDiscard={confirmNavigation}
      />
    </div>
  );
};

export default BreakPatternEditor;
