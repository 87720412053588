export const Constants = {
    formTypes: {
        delete: 'DELETE',
        edit: 'EDIT',
        export: 'EXPORT',
        import: 'IMPORT',
        insert: 'INSERT',
        paste: 'PASTE',
    },
    modifiedDataType: {
        add: 'add',
        cut: 'cut',
        delete: 'delete',
    },
    weekdays: {
        Mon: 1,
        Tue: 2,
        Wed: 3,
        Thu: 4,
        Fri: 5,
        Sat: 6,
        Sun: 7,
    }
}