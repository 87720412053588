import { HttpMethods, APIContexts } from 'enums';
import { apiRequestHandler, apiHost } from '.';

const apiContext = `${APIContexts.SEASON}/`;

export const GetSeriesAPI = ({ queryParams, onSuccess, ...rest }) => {
  const api = 'GetSeries';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.channelId}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetSeasonBySeriesAPI = ({ queryParams, onSuccess, ...rest }) => {
  const api = 'GetSeasonBySeries';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.seriesID}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};
