export const removeLastChannelSelected = () => {
  return localStorage.removeItem('latestChannel');
};

export const removeSavedDateRange = () => {
  return localStorage.removeItem('latestNewScheduleDateRange');
};

export const getAllLastAccessed = () => {
  return JSON.parse(localStorage.getItem('allLastAccessed'));
};
// Contains last accessed channel, last set date range in New Schedule and last accessed function (last active tab)
export const saveAllLastAccessed = (payload) => {
  const sessionInfo = getAllLastAccessed();
  const updatedSessionInfo = {
    ...sessionInfo,
    ...payload,
  };
  // localStorage.setItem('allLastAccessed', JSON.stringify(updatedSessionInfo));
};

export const removeAllLastAccessed = () => {
  return localStorage.removeItem('allLastAccessed');
};
