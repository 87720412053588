
export const AppModules = {
    CHANNEL_SETUP: {
        name: 'Channel Setup',
        subModules: [
            {
                id: 'configuration',
                name: 'Configuration',
                path: '/channel-profiles',
                tabs: [{ id: 'profile', name: 'Profile' }, { id: 'business_rules', name: 'Business Rules' }, { id: 'scoring', name: 'Scoring' }]
            },
            {
                id: 'architecture',
                name: 'Architecture',
                path: '/architecture',
                tabs: [{ id: 'program', name: 'Program' }, { id: 'grading', name: 'Grading' }, { id: 'minutage', name: 'Minutage' }]
            },
            {
                id: 'break-patterns',
                name: 'Break Patterns',
                path: '/break-patterns',
                tabs: null
            }
        ]
    },
    PLANNING: {
        name: 'Planning',
        subModules: [
            {
                id: 'title-groups',
                name: 'Title Groups',
                path: '/title-groups',
                tabs: null
            },
            {
                id: 'inclusions',
                name: 'Inclusions',
                path: '/inclusion',
                tabs: [{ id: 'titles', name: 'Titles' }, { id: 'title-groups', name: 'Title Groups' }, { id: 'interstitials', name: 'Interstitials' }, { id: 'seasions', name: 'Seasons' }]
            },
            {
                id: 'exclusions',
                name: 'Exclusions',
                path: '/title-exclusions',
                tabs: null
            }
        ]
    },
    SCHEDULES: {
        name: 'Schedules',
        subModules: [
            {
                id: 'view',
                name: 'view',
                path: '/view-schedules',
                tabs: null
            },
            {
                id: 'new',
                name: 'New',
                path: '/schedule',
                tabs: null
            },
            {
                id: 'submission',
                name: 'Submission',
                path: '/submission',
                tabs: null
            }
        ]
    },
    SETTINGS: {
        name: 'Settings',
        subModules: [
            {
                id: 'users',
                name: 'Users',
                path: '/users',
                tabs: null
            },
        ]
    },

}

export const SuperAdminModules = {
    ORGANISATIONS:
    {
        name: 'Organisations',
        path: '/organisation',
        subModules: null
    },
    USERS:
    {
        name: 'Users',
        path: '/users',
        subModules: null,
    },
    CHANNEL_GROUPS:
    {
        name: 'Channel Groups',
        path: '/channel-groups',
        subModules: null,
    },
    CHANNELS:
    {
        name: 'Channels',
        path: '/channels',
        subModules: null,
    },
    SETTINGS:
    {
        name: 'Settings',
        subModules: [{
            id: 'email-triggers',
            name: 'Email Triggers',
            path: '/email-triggers',
            tab: null,
        }]
    },
}